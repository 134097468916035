import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

export default function PageNumber({ size, page, count, onChange }) {
  return (
    <Stack spacing={2}>
      <Pagination
        count={count || 1}
        size={size}
        page={page || 1}
        onChange={onChange}
      />
    </Stack>
  );
}
