import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Icon from "@mui/material/Icon";
import { useNavigate } from "react-router-dom";

export default function Banner({ database }) {
  const navigate = useNavigate();
  return (
    <div>
      <Box
        sx={{
          background: `url(${process.env.PUBLIC_URL}/img/banner.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          minHeight: "400px",
        }}
      >
        <Container maxWidth="lg">
          <div
            style={{
              fontSize: "2rem",
              paddingTop: "90px",
              color: "#fff",
              fontWeight: 700,
            }}
          >
            {database && database.name}
          </div>
          <div style={{ padding: "20px 0px" }}>
            <TextField
              id="keyword"
              name="keyword"
              variant="outlined"
              InputProps={{
                endAdornment: <Icon>search</Icon>,
                style: { background: "#fff" /*, width: "450px"*/ },
              }}
              style={{ maxWidth: "450px" }}
              onKeyUp={(e) =>
                e.key === "Enter" &&
                navigate(
                  process.env.PUBLIC_URL + "/search?keyword=" + e.target.value
                )
              }
              fullWidth
            />
          </div>
        </Container>
      </Box>
    </div>
  );
}
