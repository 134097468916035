import React, { useState, useEffect, Fragment } from "react";

// import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
// import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useParams /*, useNavigate, useSearchParams*/ } from "react-router-dom";
import { fetchData } from "./js/main";
import { Container } from "@mui/system";

import Header from "./components/Header";
import Footer from "./components/Footer";
// import PageNumber from "./components/PageNumber";
// import Loading from "./components/Loading";
import ItemField from "./components/ItemField";
import { endpoint } from "./const";
import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

export default function Item({ profile }) {
  const { item_id } = useParams();
  const [item, setItem] = useState();
  useEffect(() => {
    (async () => {
      let item = await fetchData("get", "/item/" + item_id);
      setItem(item);
    })();
  }, [item_id]);

  return (
    <div>
      <Header profile={profile} />
      {item && (
        <Container>
          <ItemRecord item={item} collection={item.collection} />
        </Container>
      )}
      <br />
      <Footer />
    </div>
  );
}

function ItemRecord({ item, collection }) {
  const [titleField, setTitleField] = useState();
  const [compairFields, setCompairFields] = useState();
  const [files, setFiles] = useState([]);
  const [showItem, setShowItem] = useState(false);
  useEffect(() => {
    (async () => {
      if (item) {
        let title = item.metadata.filter((j) => j.field_name === "title");
        if (title[0]) setTitleField(title[0]);
        let files = await fetchData("get", "/item/file/" + item._id);
        setFiles(files);
        let compairFields = item.metadata.filter(
          (j) => j.field_name === "compair"
        );
        if (compairFields[0] && compairFields[0].childs) {
          setCompairFields(compairFields[0].childs);
        }
        setShowItem(true);
      }
    })();
  }, [item]);

  return (
    <div>
      {showItem === true && (
        <Grid container style={{ padding: "20px 0px" }}>
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            xl={3}
            style={{ padding: "10px 0px" }}
          >
            <Box
              sx={{
                backgroundColor: "primary.dark",
                background: `linear-gradient(180deg, rgba(86,21,113,1) 0%, rgba(86,21,113,0.5497549361541492) 70%, rgba(193,169,203,1) 100%)`,
              }}
              style={{
                width: "180px",
                margin: "10px auto auto auto",
                height: "220px",
                border: "1px solid #ccc",
              }}
            >
              {titleField && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: ".6rem",
                    padding: "30px 10px",
                    borderBottom: "1px solid #C1A8CB",
                  }}
                >
                  {titleField.value}
                </div>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={9}>
            {titleField && (
              <h3 className="bold" style={{ marginBottom: "5px" }}>
                {titleField.value}
              </h3>
            )}

            <Button
              component="a"
              style={{
                margin: "0px",
                padding: "0px 0px",
                textTransform: "none",
              }}
              href={process.env.PUBLIC_URL + "/collection/" + collection.code}
              className="bold"
            >
              Collection : {collection.name}
            </Button>
            <br />
            <br />
            {item.metadata
              .filter(
                (j) =>
                  j.value !== "" &&
                  j.field_name !== "title" &&
                  j.field_name !== "compair"
              )
              .map((field, key) => (
                <Fragment key={key}>
                  <ItemField
                    field={field}
                    code={collection.code}
                    params={{ target: "_blank" }}
                  />
                </Fragment>
              ))}
            {compairFields && <CompairTable compairFields={compairFields} />}

            {files[0] && (
              <Grid container>
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={3}
                  xl={2}
                  className="bold"
                  style={{ color: "#222" }}
                >
                  File
                </Grid>
                <Grid item xs={12} md={8} lg={9} xl={10}>
                  {files.map((file) => (
                    <div key={file._id}>
                      <Button
                        component="a"
                        style={{
                          margin: "0px",
                          padding: "0px 0px",
                          textTransform: "none",
                        }}
                        target="_blank"
                        href={endpoint.repository + "/file/" + file._id}
                      >
                        {file.label}
                      </Button>
                    </div>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
}

function CompairTable({ compairFields }) {
  const [universities, setUniversities] = useState();
  const [fields, setFields] = useState();
  useEffect(() => {
    if (compairFields) {
      let universities = [];
      let fields = [];
      for (let field of compairFields) {
        let fieldValue = [];
        for (let value of field.value) {
          let i = universities.findIndex((j) => j.name === value.value0);
          if (i < 0) universities.push({ name: value.value0 });
          fieldValue.push({
            university: value.value0,
            value: value.value1,
            field_name: field.field_name,
          });
        }
        fields.push({
          label: field.label,
          name: field.field_name,
          values: fieldValue,
        });
      }
      console.log(fields);
      setUniversities(universities);
      setFields(fields);
    }
  }, [compairFields]);

  function showValue(university, field) {
    let i = field.values.findIndex((j) => j.university === university);
    if (i > -1) return field.values[i].value;
    else return "-";
  }

  return (
    <>
      {universities && fields && (
        <>
          <Typography component="div" className="bold">
            Compair Table
          </Typography>

          <TableContainer component={Paper} sx={{ marginTop: "10px" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">ข้อมูลเปรียบเทียบ</TableCell>
                  {universities.map((u, key) => (
                    <TableCell align="center" key={key}>
                      {u.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {fields.map((field, key) => (
                  <TableRow key={key}>
                    <TableCell align="center">{field.label}</TableCell>
                    {universities.map((u, key) => (
                      <TableCell key={key} align="center">
                        {/* {JSON.stringify(
                          field.values.filter((j) => j.university === u.name)[0]
                        )} */}
                        {showValue(u.name, field)}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </>
  );
}
