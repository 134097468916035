exports.database_id = "630373986e27677e402c5ea9";

exports.endpoint = {
  repository: "https://cloud-3001.lib.cmu.ac.th/knowledge/client",
  pulinetcard: "https://cloud-3001.lib.cmu.ac.th/pulinetcard",
};

exports.server_token = {
  pulinetcard: "Bearer utz7jogGpL3hdtazZPj1apxsdk8FMm",
};
