import { endpoint, database_id } from "../const";
import axios from "axios";

var cookie = JSON.parse(localStorage.getItem(database_id + "_cookie"));

export function fetchData(method, path, data) {
  return new Promise(async (resolve, error) => {
    try {
      let result = await axios({
        method,
        url: endpoint.repository + path,
        data,
        headers: { Authorization: cookie ? cookie.access_token : null },
      });
      resolve(result.data);
    } catch (e) {
      if (e.response.data && e.response.data.error)
        alert(e.response.data.error.message);
      resolve(null);
    }
  });
}

export function getProfile() {
  return new Promise(async (resolve) => {
    try {
      if (cookie && cookie.access_token) {
        let profile = await fetchData("get", "/auth");
        resolve(profile);
      } else resolve(null);
    } catch (e) {
      resolve(null);
    }
  });
}

export function ValidateEmail(mail) {
  if (!mail || mail === "") return false;
  else {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.match(validRegex)) {
      return true;
    }
    return false;
  }
}

/*

exports.test = () => {
  console.log("test");
};

exports.fetchData = (method, path, data) => {
  return new Promise(async (resolve) => {
    try {
      let result = await axios({
        method,
        url: endpoint.repository + path,
        data,
      });
      resolve(result.data);
    } catch (e) {
      if (e.response.data && e.response.data.error)
        alert(e.response.data.error.message);
      resolve(null);
    }
  });
};
*/
