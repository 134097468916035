import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Icon from "@mui/material/Icon";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { purple } from "@mui/material/colors";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { QRCodeSVG } from "qrcode.react";
import { database_id, endpoint, server_token } from "./const";
import { fetchData } from "./js/main";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://pulinet.org/">
        https://pulinet.org/
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function SignIn() {
  const [form, setForm] = useState({ database_id });
  useEffect(() => {
    localStorage.removeItem(database_id + "_cookie");
  }, []);

  async function login() {
    let result = await fetchData("post", "/auth", form);
    if (result && result.access_token) {
      localStorage.setItem(
        database_id + "_cookie",
        JSON.stringify({ access_token: result.access_token })
      );
      window.location = process.env.PUBLIC_URL + "/";
    }
  }
  return (
    <div>
      <Header />
      <Grid container>
        <Grid item xs={12} md={6} component={Container}>
          <CssBaseline />
          <Box
            sx={{
              marginTop: 5,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in via Account
            </Typography>
            <Box noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="account"
                label="Email"
                type="email"
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setForm({ ...form, account: e.target.value })}
                value={form.account || ""}
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                InputLabelProps={{ shrink: true }}
                type="password"
                value={form.pwd || ""}
                onChange={(e) => setForm({ ...form, pwd: e.target.value })}
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                color="primary"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={login}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs></Grid>
                <Grid item>
                  <Link
                    href={process.env.PUBLIC_URL + "/signup"}
                    variant="body2"
                  >
                    {"Sign Up"}
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <CssBaseline />
          <PULINETSignInForm />
        </Grid>
      </Grid>
      <Copyright sx={{ mt: 8, mb: 4 }} />
      <Footer />
    </div>
  );
}

function PULINETSignInForm() {
  const [institutes, setInstitutes] = useState([]);
  const [form, setForm] = useState({});
  const [dialogState, setDialogState] = useState({ open: false });
  useEffect(() => {
    (async () => {
      let result = await axios.get(endpoint.pulinetcard + "/institute");
      result = result.data;
      setInstitutes(result);
      setForm({ institute: result[0]._id });
    })();
  }, []);

  async function PULINETLogin() {
    let i = institutes.findIndex((j) => j._id === form.institute);
    if (i > -1) {
      let external_key = uuidv4();
      let send = {
        institute_id: form.institute,
        external_key,
      };

      let result = await axios.put(endpoint.pulinetcard + "/session", send, {
        headers: { Authorization: server_token.pulinetcard },
      });
      if (result && result.data && result.data.code) {
        result = result.data;
        setDialogState({
          open: true,
          url: result.institute.login_url + "?session_id=" + result.code,
          code: result.code,
          external_key,
        });
      }
      // console.log(institutes[i].login_url + "?session_id=" + result.data.code);
    }
  }
  return (
    <div>
      <QRCodeDialog
        state={dialogState}
        onClose={() => setDialogState({ ...dialogState, open: false })}
      />
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
          <Icon>credit_card</Icon>
        </Avatar>
        <Typography component="h1" variant="h5">
          PULINET Member
        </Typography>
        <Container maxWidth="sm" style={{ paddingTop: "20px" }}>
          <Card
            sx={{
              maxWidth: 400,
              margin: "auto",
              display: "flex",
              borderStyle: "solid",
              borderWidth: "3px",
              borderRadius: "10px",
              borderColor: purple[300],
            }}
            // onClick={PULINETLogin}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {/* <CardMedia
                  component="img"
                  height="250"
                  image={process.env.PUBLIC_URL + "/img/pulinet-logo.png"}
                  sx={{ height: "120px", width: "170px" }}
                  alt="green iguana"
                /> */}
              <CardMedia
                style={{
                  padding: "5px 20px",
                  width: "400px",
                }}
              >
                <img
                  style={{ width: "100px" }}
                  src={process.env.PUBLIC_URL + "/img/pulinet-logo.png"}
                  alt=""
                />
              </CardMedia>
              <CardContent
                style={{ borderTop: "1px solid #ddd", color: "#333" }}
              >
                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="sarabun bold"
                  color="primary"
                  style={{ lineHeight: "1rem" }}
                >
                  PULINET CARD
                </Typography>
                {form.institute && (
                  <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel id="demo-simple-select-label" shrink>
                      Institute
                    </InputLabel>
                    <Select
                      native
                      labelId="demo-simple-select-label"
                      label="Institute"
                      value={form.institute}
                      onChange={(e) =>
                        setForm({ ...form, institute: e.target.value })
                      }
                    >
                      {institutes.map((item, key) => (
                        <option key={key} value={item._id}>
                          {item.name}
                        </option>
                      ))}
                    </Select>
                    <Button
                      variant="contained"
                      style={{ marginTop: "10px" }}
                      onClick={PULINETLogin}
                    >
                      Create QR Code
                    </Button>
                  </FormControl>
                )}
              </CardContent>
            </Box>
          </Card>
        </Container>
      </Box>
    </div>
  );
}

function QRCodeDialog({ state, onClose }) {
  const [interval, setInterval] = useState();
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (state.open === true) {
      setInterval({
        no: 1,
        code: state.code,
        external_key: state.external_key,
      });
      setRefresh(true);
    } else setRefresh(false);
  }, [state]);

  useEffect(() => {
    (async () => {
      if (interval && refresh === true) {
        let result = await axios.get(
          endpoint.pulinetcard + "/session/" + interval.code,
          { headers: { Authorization: interval.external_key } }
        );
        if (!result.data) {
          setTimeout(() => {
            let i = interval.no;
            i++;
            console.log(i);
            setInterval({ ...interval, no: i });
          }, 3000);
        } else {
          result = result.data;
          setRefresh(false);
          let profile = {
            account: result.email,
            user_type: "pulinet_card",
            prefix: result.prefix,
            first_name: result.first_name,
            last_name: result.last_name,
            database: database_id,
          };
          result = await fetchData("put", "/auth/register", profile);
          let newCookie = JSON.parse(
            localStorage.getItem(database_id + "_cookie")
          );
          if (!newCookie) newCookie = {};
          newCookie.access_token = result.access_token;
          localStorage.setItem(
            database_id + "_cookie",
            JSON.stringify(newCookie)
          );
          window.location = process.env.PUBLIC_URL + "/";
        }
      }
    })();
  }, [refresh, interval]);

  return (
    <div>
      <Dialog open={state.open}>
        <DialogTitle id="alert-dialog-title">Sign In</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <QRCodeSVG value={state.url} size={180} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setInterval(null);
              onClose();
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
