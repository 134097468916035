import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home";
import Collection from "./Collection";
import Item from "./Item";
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import PULINETSignIn from "./PULINETSignIn";
import { getProfile } from "./js/main";

const routes = [
  {
    path: "/",
    component: Home,
  },
  {
    path: "/search",
    component: Collection,
  },
  {
    path: "/search/:page_no",
    component: Collection,
  },
  {
    path: "/collection/:code",
    component: Collection,
  },
  {
    path: "/collection/:code/:page_no",
    component: Collection,
  },
  {
    path: "/item/:item_id",
    component: Item,
  },
  {
    path: "/signup",
    component: SignUp,
  },
  {
    path: "/signin",
    component: SignIn,
  },
  {
    path: "/signin/pulinet",
    component: PULINETSignIn,
  },
];

function App() {
  const [profile, setProfile] = useState();
  useEffect(() => {
    (async () => {
      let p = await getProfile();
      setProfile(p);
    })();
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routes
          .filter((j) => j.component)
          .map((path, key) => (
            <Route
              exact
              path={`${process.env.PUBLIC_URL}${path.path}`}
              element={<path.component page={path} profile={profile} />}
              key={key}
            />
          ))}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
