import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Icon from "@mui/material/Icon";

export default function Header({ profile }) {
  const styles = {
    topMenu: {
      color: "#fff",
      fontWeight: 300,
      marginRight: "15px",
    },
  };
  return (
    <>
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={6} style={{ padding: "10px 0px" }}>
            <img
              src={process.env.PUBLIC_URL + "/img/pulinet-logo.png"}
              alt=""
              style={{ height: "80px" }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            align="right"
            alignItems="flex-end"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingBottom: "5px",
            }}
          >
            <Button
              startIcon={<Icon>key</Icon>}
              component="a"
              href={process.env.PUBLIC_URL + "/signin"}
              style={{ fontSize: ".8rem", color: "#999", fontWeight: 400 }}
            >
              {profile ? profile.account : "Login"}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          backgroundColor: "primary.dark",
        }}
        //   style={{ background: "#6a1b9a" }}
      >
        <Container maxWidth="lg">
          <Button
            style={styles.topMenu}
            component="a"
            href={process.env.PUBLIC_URL + "/"}
          >
            Home
          </Button>
          <Button
            style={styles.topMenu}
            component="a"
            href="https://pulinet.org"
            target="_blank"
          >
            PULINET
          </Button>
          <Button style={styles.topMenu}>About</Button>
        </Container>
      </Box>
    </>
  );
}
