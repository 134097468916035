import React, { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";

export default function Loading(props) {
  const [state, setState] = useState();
  useEffect(() => {
    setState(props.state);
  }, [props]);

  return (
    <div style={{ textAlign: "center", paddingTop: "100px" }}>
      {state && state.loading === true && (
        <>
          <CircularProgress />
          <br />
          <div style={{ color: "#999", marginTop: "20px" }}>Loading</div>
        </>
      )}
    </div>
  );
}
