import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import { endpoint, database_id } from "./const";
import { fetchData } from "./js/main";

export default function PULINETSignIn() {
  const [searchParams] = useSearchParams();
  const otp = searchParams.get("otp");
  useEffect(() => {
    (async () => {
      if (otp) {
        let result = await axios.get(
          endpoint.pulinetcard + "/session/otp/" + otp
        );
        result = result.data;
        console.log(result);
        let profile = {
          account: result.email,
          prefix: result.prefix,
          first_name: result.first_name,
          last_name: result.last_name,
          database: database_id,
          user_type: "pulinet",
        };
        result = await fetchData("put", "/auth/register", profile);
        if (result && result.access_token) {
          localStorage.setItem(
            database_id + "_cookie",
            JSON.stringify({ access_token: result.access_token })
          );
          window.location = process.env.PUBLIC_URL + "/";
        }
      }
    })();
  }, [otp]);

  return <div></div>;
}
