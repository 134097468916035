import React, { useState } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import { fetchData, ValidateEmail } from "./js/main";
import { database_id } from "./const";

const PageHeader = () => (
  <Grid container>
    <Grid item style={{ padding: "22px 15px", paddingLeft: "0px" }}>
      <Icon style={{ fontSize: "1.8rem" }}>person_add</Icon>
    </Grid>
    <Grid item>
      <h2 className="bold">Sign Up</h2>
    </Grid>
  </Grid>
);

export default function SignUp() {
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [register, setRegister] = useState();
  function changeFormValue(e) {
    let val = e.target.value;
    setForm({ ...form, [e.target.id]: val });
  }
  function checkBeforeSave() {
    return new Promise(async (resolve) => {
      let r = true;
      let err = {};
      let fields = ["prefix", "first_name", "last_name", "account", "password"];
      if (form.accept !== true) {
        r = "Please accept service condition";
        err.accept = true;
      }
      for (let field of fields) {
        if (!form[field] || form[field] === "") {
          r = "Information error";
          err[field] = true;
        }
      }
      console.log(form.account);
      if (ValidateEmail(form.account) === false) {
        r = "Email format error";
        err.account = true;
      }
      if (form.password !== form.re_password) {
        r = "Password did not equal with re-password";
        err.password = true;
        err.re_password = true;
      }
      setError(err);
      resolve(r);
    });
  }
  async function save() {
    let chk = await checkBeforeSave();
    if (chk !== true) alert(chk);
    else {
      form.database = database_id;
      let result = await fetchData("put", "/auth/register", form);
      if (result._id) {
        setRegister(result);
      }
    }
  }
  return (
    <div>
      <Header />
      <Container style={{ paddingTop: "15px" }}>
        <PageHeader />
        <div style={{ minHeight: "65vh" }}>
          {!register ? (
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} style={{ padding: "10px 15px" }}>
                <div className="bold" style={{ marginBottom: "15px" }}>
                  Personal Information
                </div>
                <PersonalForm
                  form={form}
                  changeForm={changeFormValue}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} md={6} style={{ padding: "10px 15px" }}>
                <div className="bold" style={{ marginBottom: "15px" }}>
                  Account Information
                </div>
                <AccountForm
                  form={form}
                  changeForm={changeFormValue}
                  error={error}
                />
              </Grid>
              <Grid item xs={12} style={{ padding: "10px 15px" }}>
                <FormControlLabel
                  onChange={(e) =>
                    setForm({ ...form, accept: e.target.checked })
                  }
                  control={<Checkbox />}
                  label={<div>ข้าพเจ้ายอมรับเงื่อนไขการให้บริการ</div>}
                />
                {error.accept === true && (
                  <FormHelperText
                    style={{
                      color: "#f00",
                      paddingLeft: "32px",
                      marginTop: "0px",
                    }}
                  >
                    Please accept service condition
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12} style={{ padding: "20px 15px" }}>
                <Button
                  variant="contained"
                  startIcon={<Icon>app_registration</Icon>}
                  sx={{ width: "200px" }}
                  onClick={save}
                >
                  Register
                </Button>
              </Grid>
            </Grid>
          ) : (
            <div style={{ textAlign: "center" }}>
              <h2 className="bold">Register completed</h2>
              <div>Please check email</div>
              <div style={{ color: "#00f" }} className="bold">
                {register && register.account}
              </div>
              <div>for activate account</div>
            </div>
          )}
        </div>
      </Container>
      <br />
      <Footer />
    </div>
  );
}

function AccountForm({ form, changeForm, error }) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={error.account}
            size="small"
            label="Email"
            id="account"
            placeholder="อีเมล"
            type="email"
            value={form.account || ""}
            InputLabelProps={{ shrink: true }}
            onChange={changeForm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={error.password}
            size="small"
            label="Create Password"
            id="password"
            placeholder="ตั้งรหัสผ่าน"
            type="password"
            value={form.password || ""}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={changeForm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={error.re_password}
            size="small"
            label="Re-Password"
            id="re_password"
            placeholder="ยืนยันรหัสผ่าน"
            type="password"
            value={form.re_password || ""}
            InputLabelProps={{ shrink: true }}
            onChange={changeForm}
          />
        </Grid>
      </Grid>
    </div>
  );
}

function PersonalForm({ form, changeForm, error }) {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            error={error.prefix}
            size="small"
            label="Prefix"
            id="prefix"
            placeholder="คำนำหน้าชื่อ"
            value={form.prefix || ""}
            InputLabelProps={{ shrink: true }}
            onChange={changeForm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            error={error.first_name}
            label="First Name"
            id="first_name"
            placeholder="ชื่อ"
            value={form.first_name || ""}
            InputLabelProps={{ shrink: true }}
            onChange={changeForm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            size="small"
            label="Last Name"
            error={error.last_name}
            id="last_name"
            placeholder="นามสกุล"
            value={form.last_name || ""}
            InputLabelProps={{ shrink: true }}
            onChange={changeForm}
          />
        </Grid>
      </Grid>
    </div>
  );
}
