import React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

export default function ItemField({ field, code, params }) {
  let target = (params && params.target) || "_self";
  function onHref(value) {
    let url =
      process.env.PUBLIC_URL +
      "/collection/" +
      code +
      "?q=" +
      encodeURIComponent(JSON.stringify({ [field.metadata_id]: value }));
    if (!code) {
      url =
        process.env.PUBLIC_URL +
        "/search/" +
        "?q=" +
        encodeURIComponent(JSON.stringify({ [field.metadata_id]: value }));
    }
    return url;
  }
  return (
    <div style={{ marginBottom: "10px" }}>
      <Grid container>
        <Grid
          item
          xs={12}
          md={4}
          lg={3}
          xl={2}
          className="bold"
          style={{ color: "#222" }}
        >
          {field.label}
        </Grid>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          {Array.isArray(field.value) ? (
            <>
              {field.value.map((f, key) => (
                <div key={key}>
                  <Button
                    component="a"
                    style={{
                      margin: "0px",
                      padding: "0px 0px",
                      lineHeight: "1.3",
                      textTransform: "none",
                      minWidth: "5px",
                    }}
                    href={onHref(f.value)}
                    target={target}
                  >
                    {f.value}
                  </Button>
                </div>
              ))}
            </>
          ) : (
            <FieldValue field={field} />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

function FieldValue({ field }) {
  return (
    <div>
      {(() => {
        switch (field.input_type) {
          case "URI":
            return (
              <Button
                component="a"
                style={{
                  margin: "0px",
                  padding: "0px 0px",
                  lineHeight: "1.3",
                  textTransform: "none",
                  minWidth: "5px",
                }}
                href={field.path}
                target="_blank"
              >
                {field.value || field.path}
              </Button>
            );
          case "ARRAY":
            return <>ARRAY</>;
          default:
            return <>{field.value || "-"}</>;
        }
      })()}
    </div>
  );
}
