import React, { useState, useEffect } from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { fetchData } from "./js/main";
import { Container } from "@mui/system";
import { database_id } from "./const";

import Header from "./components/Header";
import Footer from "./components/Footer";
import PageNumber from "./components/PageNumber";
import Loading from "./components/Loading";
import ItemField from "./components/ItemField";

export default function Collection({ profile }) {
  const navigate = useNavigate();
  // const { code, page_no } = useParams();
  const params = useParams();
  const [state, setState] = useState();
  //   {
  //   code,
  //   page_no,
  //   limit: 50,
  // }
  const [collection, setCollection] = useState();
  const [loadState, setLoadState] = useState();
  const [items, setItems] = useState();
  const [keyword, setKeyword] = useState();
  const [pageCount, setPageCount] = useState(1);
  const [filterQuery, setFilterQuery] = useState();

  const [searchParams /*, setSearchParams*/] = useSearchParams();
  useEffect(() => {
    // Create Page State
    let page_no = params.page_no || 1;
    let limit = 50;
    let { code } = params;
    if (page_no) setState({ code, page_no: parseInt(page_no), limit });
  }, [params]);

  useEffect(() => {
    (async () => {
      if (state) {
        let queryString = JSON.parse(searchParams.get("q"));
        let keywordString = searchParams.get("keyword");
        if (keywordString && keywordString !== "") setKeyword(keywordString);
        else setKeyword("");
        setFilterQuery({ queryString, keywordString });
      }
    })();
  }, [searchParams, state]);

  useEffect(() => {
    function query2match(query) {
      return new Promise((resolve) => {
        let r = [];
        for (let key of Object.keys(query)) {
          let k = key + ".value";
          r.push({ [k]: query[key] });
        }
        resolve(r);
      });
    }
    (async () => {
      let state = document.getElementById("inputState");
      if (state && filterQuery && (!state.error || state.error !== true)) {
        state = JSON.parse(state.value);
        let { code, limit, page_no } = state;
        let skip = (page_no - 1) * limit;
        let match = {};
        // Check collection page or search page
        setLoadState({ loading: true });
        if (code) {
          // Collection Page Query
          let result = await fetchData("get", "/collection/" + code);
          if (result) {
            let { queryString, keywordString } = filterQuery;
            setCollection(result);
            match = { $and: [{ collection_id: result._id }] };
            if (queryString) {
              queryString = await query2match(queryString);
              match["$and"] = match["$and"].concat(queryString);
            }
            result = await fetchData("post", "/item", {
              collection_code: code,
              match,
              skip,
              limit,
              keyword: keywordString,
            });
            let pageCount = Math.ceil(result.count / limit);
            setPageCount(pageCount);
            setItems(result);
            setLoadState({ loading: false });
          } else {
            setState({ error: true });
          }
        } else {
          let { queryString, keywordString } = filterQuery;
          match = { $and: [] };
          if (queryString) {
            queryString = await query2match(queryString);
            match["$and"] = match["$and"].concat(queryString);
          } else match = null;
          let result = await fetchData("post", "/item", {
            database_id,
            match,
            skip,
            limit,
            keyword: keywordString,
          });
          let pageCount = Math.ceil(result.count / limit);

          setPageCount(pageCount);
          setItems(result);
          setLoadState({ loading: false });
        }
      }
    })();
  }, [filterQuery]);

  async function goSearch() {
    let keyword = document.getElementById("keyword").value.trim();

    let url = process.env.PUBLIC_URL + "/collection/" + params.code;
    if (!params.code) url = process.env.PUBLIC_URL + "/search";
    if (keyword && keyword !== "")
      url += "?keyword=" + encodeURIComponent(keyword);
    navigate(url);
  }
  function changepage(e, val) {
    let url = process.env.PUBLIC_URL + "/collection/" + state.code + "/" + val;
    if (!state.code) {
      url = process.env.PUBLIC_URL + "/search/" + val;
    }
    if (filterQuery.keywordString && filterQuery.keywordString !== "")
      url += "?keyword=" + filterQuery.keywordString;
    else if (filterQuery.queryString && filterQuery.queryString !== "")
      url +=
        "?q=" + encodeURIComponent(JSON.stringify(filterQuery.queryString));
    navigate(url);
  }
  return (
    <div>
      <Header profile={profile} />
      <input
        type="hidden"
        id="inputState"
        value={state ? JSON.stringify(state) : ""}
      />
      {state && state.error === true && (
        <div
          style={{
            textAlign: "center",
            minHeight: "50vh",
            paddingTop: "150px",
          }}
        >
          <div className="bold">No Permission to Access</div>
          <div style={{ marginTop: "20px" }}>
            <Button
              size="large"
              component="a"
              variant="contained"
              style={{ width: "250px" }}
              href={process.env.PUBLIC_URL + "/signin"}
              startIcon={<Icon>key</Icon>}
            >
              Sign In
            </Button>
            <br />
            <Button
              size="large"
              component="a"
              variant="outlined"
              style={{ width: "250px", marginTop: "10px" }}
              startIcon={<Icon>person_add</Icon>}
              href={process.env.PUBLIC_URL + "/signup"}
            >
              Sign Up
            </Button>
          </div>
        </div>
      )}
      {items && state && !state.error === true && (
        <Container>
          <br />
          {collection && (
            <Button
              component="a"
              style={{
                margin: "10px auto 15px",
                padding: "0px 0px",
                lineHeight: "1.3",
                textTransform: "none",
              }}
              href={process.env.PUBLIC_URL + "/collection/" + collection.code}
            >
              <div className="bold" style={{ fontSize: "1.3rem" }}>
                {collection.name}
              </div>
            </Button>
          )}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              md={8}
              lg={9}
              xl={10}
              style={{ padding: "10px 0px" }}
            >
              <div style={{ color: "#333" }}>
                <span className="bold">Result :</span>
                <span style={{ padding: "0px 10px" }}>{items.count}</span>
                record(s)
              </div>
              {keyword && keyword !== "" && (
                <div style={{ paddingTop: "5px" }}>
                  Search Keyword :{" "}
                  <span style={{ color: "#f00" }} className="bold">
                    {keyword}
                  </span>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              xl={2}
              align="right"
              style={{ padding: "7px 0px" }}
            >
              <TextField
                size="small"
                variant="outlined"
                id="keyword"
                // value={keyword || ""}
                label="Search"
                fullWidth
                InputProps={{
                  endAdornment: <Icon>search</Icon>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Keyword..."
                onKeyUp={(e) => e.key === "Enter" && goSearch()}
                // onChange={(e) => setKeyword(e.target.value)}
              />
            </Grid>
          </Grid>
          <PageNumber
            size="small"
            count={pageCount}
            page={parseInt(state.page_no)}
            onChange={(e, val) => {
              changepage(e, val);
            }}
          />
          <div style={{ minHeight: "50vh", marginBottom: "20px" }}>
            {loadState && loadState.loading === true ? (
              <Loading state={loadState} />
            ) : (
              <>
                {items.result && items.result.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      padding: "150px 0px",
                      color: "#999",
                    }}
                  >
                    No Match Found
                  </div>
                )}
                {items.result.map((item) => (
                  <ItemRecord item={item} key={item._id} code={state.code} />
                ))}
              </>
            )}
          </div>
          <PageNumber
            size="small"
            count={pageCount}
            page={parseInt(state.page_no)}
            onChange={(e, val) => changepage(e, val)}
          />
        </Container>
      )}
      <br />
      <Footer />
    </div>
  );
}

function ItemRecord({ item, code }) {
  const [titleField, setTitleField] = useState();
  const [showItem, setShowItem] = useState(false);
  useEffect(() => {
    if (item) {
      let title = item.metadata.filter((j) => j.field_name === "title");
      if (title[0]) setTitleField(title[0]);
      setShowItem(true);
    }
  }, [item]);

  return (
    <div>
      {showItem === true && (
        <Grid
          container
          style={{ padding: "20px 0px", borderBottom: "1px solid #ddd" }}
        >
          <Grid
            item
            xs={12}
            md={5}
            lg={4}
            xl={3}
            style={{ padding: "10px 0px" }}
          >
            <Box
              sx={{
                backgroundColor: "primary.dark",
                background: `linear-gradient(180deg, rgba(86,21,113,1) 0%, rgba(86,21,113,0.5497549361541492) 70%, rgba(193,169,203,1) 100%)`,
              }}
              style={{
                width: "180px",
                margin: "10px auto auto auto",
                height: "220px",
                border: "1px solid #ccc",
              }}
            >
              {titleField && (
                <div
                  style={{
                    textAlign: "center",
                    color: "#fff",
                    fontSize: ".6rem",
                    padding: "30px 10px",
                    borderBottom: "1px solid #C1A8CB",
                  }}
                >
                  {titleField.value}
                </div>
              )}
            </Box>
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Button
                variant="outlined"
                style={{ width: "180px" }}
                href={process.env.PUBLIC_URL + "/item/" + item.code}
                target="_blank"
              >
                Show Detail
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={7} lg={8} xl={9}>
            {titleField && <h3 className="bold">{titleField.value}</h3>}

            {item.metadata
              .filter((j) => j.is_key === true && j.field_name !== "title")
              .map((field, key) => (
                <ItemField key={key} field={field} code={code} />
              ))}
          </Grid>
        </Grid>
      )}
    </div>
  );
}
