import React, { useEffect, useState } from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import { Button, CardActionArea, Divider, Stack } from "@mui/material";

import Header from "./components/Header";
import Banner from "./components/Banner";
import Footer from "./components/Footer";
import { database_id } from "./const";
import { fetchData } from "./js/main";
import { useNavigate } from "react-router-dom";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function Home(props) {
  const [database, setDatabase] = useState();
  const [aiBannerOpen, setAIBannerOpen] = useState(true);
  useEffect(() => {
    (async () => {
      let database = await fetchData("get", "/database/" + database_id);
      database.collections = database.collections.filter(
        (j) => j.public_type !== "PROTECT"
      );
      setDatabase(database);
    })();
  }, []);

  return (
    <div>
      <AIDialog open={aiBannerOpen} onClose={() => setAIBannerOpen(false)} />
      {database && (
        <>
          <Header profile={props.profile} />
          <Banner database={database} />
          <div style={{ textAlign: "center" }}>
            <h1 className="bold" style={{ padding: "20px 0px" }}>
              Collections
            </h1>
            <Container>
              <Grid container spacing={3}>
                {database.collections.map((collection, key) => (
                  <Grid
                    item
                    xs={12}
                    md={6}
                    lg={4}
                    xl={3}
                    key={key}
                    align="center"
                  >
                    <CollectionCard collection={collection} />
                  </Grid>
                ))}
              </Grid>
            </Container>
            <br />
            <br />
            <br />
            <Footer />
          </div>
        </>
      )}
    </div>
  );
}

function CollectionCard({ collection }) {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() =>
        navigate(process.env.PUBLIC_URL + "/collection/" + collection.code)
      }
    >
      <CardActionArea>
        <CardMedia style={{ paddingTop: "10px" }}>
          <Icon style={{ fontSize: "6rem", color: "#684391" }}>storage</Icon>
        </CardMedia>
        <CardContent>
          <Typography
            gutterBottom
            component="div"
            style={{ height: "40px", fontSize: "1rem", color: "#444" }}
          >
            {collection.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

function AIDialog({ open, onClose }) {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>A.I Available Now</DialogTitle>
        <Divider />
        <DialogContent>
          <Stack direction={"row"} spacing={5}>
            <img
              src={process.env.PUBLIC_URL + "/img/pulinet-logo.png"}
              alt=""
              style={{ height: "100px" }}
            />
            <div>
              <div>เพื่อการเข้าถึงบทความที่มีประสิทธิภาพมากขึ้น</div>
              <div style={{ lineHeight: "35px" }}>
                ขอเชิญทุกท่านร่วมทดสอบระบบ
              </div>
              <h1
                style={{
                  fontWeight: 700,
                  padding: "0px 0px",
                  margin: "8px auto 8px",
                  color: "#7f3f97",
                }}
              >
                PULINET Archive AI
              </h1>
            </div>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            component="a"
            href="https://repository.pulinet.org/archive-ai"
            target="_blank"
            sx={{ width: "100px", marginRight: "10px" }}
          >
            Go to A.I
          </Button>
          <Button onClick={onClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
