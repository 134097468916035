import React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

export default function Footer() {
  return (
    <Box
      style={{ background: "#353A3C", padding: "20px 0px", textAlign: "left" }}
    >
      <Container>
        <h3 style={{ color: "#fff" }} className="bold">
          CONTACT PULINET
        </h3>

        <Grid container spacing={1}>
          <Grid item>
            <a
              href="https://www.facebook.com/profile.php?id=100054602673563"
              title="Facebook"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#aaa" }}
            >
              <Icon style={{ fontSize: "2.2rem" }}>facebook</Icon>
            </a>
          </Grid>
          <Grid item>
            <a
              href="http://pulinet.org"
              title="Website"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#aaa" }}
            >
              <Icon style={{ fontSize: "2.2rem" }}>language</Icon>
            </a>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
